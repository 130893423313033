/**
 * Dropdown auf den Mediadaten-Seiten
 */
const dropdown = {
    init() {
        const dropdowns = document.querySelectorAll('.js-dropdown');

        dropdowns.forEach((dropdownItem) => {
            dropdownItem.addEventListener('click', (event) => {
                const element = event.currentTarget;
                element.classList.toggle('active');

                const target = document.querySelector(element.dataset.target);
                if (!target.classList.contains('is-active')) {
                    target.classList.toggle('is-active');
                    target.style.height = `${target.scrollHeight + 20}px`;

                    return;
                }
                target.classList.toggle('is-active');
                target.style.height = '0px';
            });
        });
    },
};

export default dropdown;
