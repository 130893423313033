import Swiper from 'swiper';

function initSlider(slider) {
    const customEventName = 'galleryChange';
    const swiper = new Swiper(slider.querySelector('.js-post-image-swiper'), {
        pagination: {
            el: '.js-post-image-counter',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.js-post-image-next',
            prevEl: '.js-post-image-prev',
            disabledClass: 'hidden',
        },
        spaceBetween: 20,
        slidesPerView: 1.09,
        breakpoints: {
            768: {
                slidesPerView: 1,
            },
        },
        autoHeight: true,
        loop: true,
        preloadImages: false,
        lazy: {
            loadPrevNext: true,
        },
    });

    swiper.on('slideChange', () => {
        const galleryChangedEvent = new CustomEvent(customEventName);

        window.dispatchEvent(galleryChangedEvent);

        // Wenn die Galerie sich verändert soll Google das mitbekommen.
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: customEventName,
        });
    });
}

export default function postImageSlider() {
    const postImageSliders = document.querySelectorAll('.js-post-image-slider');

    postImageSliders.forEach((slider) => initSlider(slider));
}
