function activateForm(form) {
    if (!form) return;

    const submitButton = document.querySelector('.js-form-submit');
    const validationTokenInput = form.querySelector('.js-validation-token-input input');
    const token = validationTokenInput.value;

    if (submitButton && token.length) {
        submitButton.disabled = false;
    }
}

export default function initForm() {
    const form = document.querySelector('.js-form');
    if (!form) {
        return;
    }

    activateForm(form);
}
