class ExternalContent {
    checkInterval;

    consentedVendors = [];

    loaded = false;

    tcfApiCount = 0;

    constructor() {
        this.blockerElements = document.querySelectorAll('.js-external-content');
        if (this.blockerElements) {
            this.blockerElements.forEach(this.initBlockerElement.bind(this));
        }
        this.loaded = true;
        this.checkInterval = setInterval(this.checkSpConsent.bind(this), 5000);
    }

    /**
     * @param blockerElement any[]
     */
    initBlockerElement(blockerElement) {
        const {
            form,
            type,
        } = ExternalContent.getElementsFromBlockerElement(blockerElement);

        if (this.consentedVendors.includes(type)) {
            ExternalContent.toggleBlocker(blockerElement);
            return;
        }

        form.addEventListener('submit', (event) => {
            event.preventDefault();
            ExternalContent.toggleBlocker(blockerElement);
        });
    }

    unblockConsentedCMPVendors() {
        this.blockerElements.forEach((blocker) => {
            const {type} = blocker.dataset;
            if (this.consentedVendors.includes(type)) {
                ExternalContent.toggleBlocker(blocker);
            }
        });
    }

    /**
     * @param type string
     * @param iframe DOMElement
     */
    static loadIframeContent(type, iframe) {
        const iframeElements = ['linkedin', 'facebook', 'youtube'];
        if (iframe && iframeElements.includes(type)) {
            iframe.setAttribute('src', iframe.getAttribute('data-source'));
            iframe.removeAttribute('data-source');
        }
    }

    /**
     * @param type string
     * @param script DOMElement
     */
    static loadScriptContent(type, script) {
        const scriptElements = ['twitter', 'instagram', 'podigee'];
        if (script && scriptElements.includes(type)) {
            script.setAttribute('src', script.getAttribute('data-source'));
            script.removeAttribute('data-source');
        }
    }

    /**
     *
     * @param blockerElement DOMElement
     * @returns {{form: *, box: *, iframe: *, type: *, script: *}}
     */
    static getElementsFromBlockerElement(blockerElement) {
        const form = blockerElement.querySelector('.js-external-content-form');
        const box = blockerElement.querySelector('.js-external-content-box');
        const {type} = blockerElement.dataset;
        const script = box.querySelector('script');
        const iframe = box.querySelector('iframe');

        return {
            form,
            box,
            type,
            script,
            iframe,
        };
    }

    /**
     * @param blocker DOMElement
     */
    static toggleBlocker(blocker) {
        const {
            type,
            script,
            iframe,
        } = ExternalContent.getElementsFromBlockerElement(blocker);

        if (script) {
            ExternalContent.loadScriptContent(type, script);
        }

        if (iframe) {
            ExternalContent.loadIframeContent(type, iframe);
        }

        blocker.classList.add('active');
        blocker.setAttribute('data-active', true);
    }

    checkSpConsent() {
        if (this.tcfApiCount > 20) {
            /* eslint-disable-next-line no-console */
            console.error('__tcfapi ist nicht verfügbar.');
            clearInterval(this.checkInterval);

            return;
        }

        /* eslint-disable no-underscore-dangle */
        if (typeof window.__tcfapi === 'undefined') {
            /* eslint-disable-next-line no-console */
            console.warn('__tcfapi ist nicht verfügbar.');

            return;
        }

        clearInterval(this.checkInterval);

        window.__tcfapi('getCustomVendorConsents', 2, (data, success) => {
            /* eslint-enable no-underscore-dangle */
            if (!success) {
                /* eslint-disable-next-line no-console */
                console.error('Fehler beim Abrufen der benutzerdefinierten TCF-Vendoren:', data);

                return;
            }

            if (!Object.prototype.hasOwnProperty.call(data, 'consentedVendors') && !data.consentedVendors.length) {
                return;
            }

            Array.prototype.map.call(data.consentedVendors, (vendor) => {
                if (vendor.name === 'Facebook, Inc.') {
                    this.consentedVendors.push('facebook');
                }

                if (vendor.name === 'LinkedIn Corporation') {
                    this.consentedVendors.push('linkedin');
                }

                if (vendor.name === 'Podigee GmbH') {
                    this.consentedVendors.push('podigee');
                }

                if (vendor.name === 'X Corp.' || vendor.name === 'Twitter') {
                    this.consentedVendors.push('twitter');
                }

                if (vendor.name === 'Instagram' || vendor.name === 'Instagram, Inc.'
                    || vendor.name === ' Instagram, Inc.') {
                    this.consentedVendors.push('instagram');
                }

                if (vendor.name === 'YouTube Video' || vendor.name === 'YouTube') {
                    this.consentedVendors.push('youtube');
                }
            });

            if (this.loaded) {
                this.unblockConsentedCMPVendors();
            }
        });
        this.tcfApiCount += 1;
    }
}

export default ExternalContent;
