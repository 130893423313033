/**
 * "Mehr"-Button in den SEO-Texten auf Tag-Übersichtsseiten
 * @type {{init(): void}}
 */
const tagMore = {
    init() {
        const button = document.querySelector('.js-tag-more-button');
        const tagDescription = document.querySelector('.js-tag-description');

        if (button) {
            button.addEventListener('click', () => {
                button.style.display = 'none';
                tagDescription.classList.add('is-visible');
            });
        }
    },
};

export default tagMore;
