// ES6 Polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// IE Polyfill
import './polyfills/customEvent';

// Projekt-Vendor
import 'lazysizes';
import 'picturefill';

// Projekt-Module
import jobSlider from './modules/jobSlider';
import dropdown from './modules/dropdown';
import navigation from './modules/navigation';
import tagMore from './modules/tag-more';
import recruitingSlider from './modules/recruitingSlider';
import showMoreEvents from './modules/showMoreEvents';
import eventFormSubmit from './modules/eventFormSubmit';
import cookieWatcher, {vdiCookieWatcher} from './modules/CookieWatcher';
import audioplayer from './modules/audioplayer';
import recruitingRegistration from './modules/recruitingRegistration';
import postImageSlider from './modules/postImageSlider';
import accordion from './modules/accordion';
import subMenuHandler from './modules/subMenuHandler';
import loadScriptService from './modules/loadScriptService';
import initForm from './modules/form';
import ExternalContent from './modules/external-content';
import tableOfContent from './modules/tableOfContent';

const onReady = () => {
    jobSlider.init();
    dropdown.init();
    navigation.init();
    tagMore.init();
    recruitingSlider.init();
    showMoreEvents.init();
    eventFormSubmit.init();
    cookieWatcher.init();
    audioplayer.init();
    recruitingRegistration.init();
    accordion();
    postImageSlider();
    subMenuHandler();
    initForm();
    /* eslint-disable no-new */
    new ExternalContent();
    tableOfContent();
};

/*
Falls DOMContentLoaded schon vom document augerufen wurde,
sollen trotzdem alle Module initialisiert werden.
 */
if (
    document.readyState === 'complete'
    || (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    onReady();
} else {
    document.addEventListener('DOMContentLoaded', onReady);
}
window.vdiCookieWatcher = vdiCookieWatcher;
window.loadScriptService = loadScriptService;
