/**
 * In der Event-Liste werden zu Beginn nur 10 Events angezeigt.
 * Hiermit werden pro Klick 10 weitere eingebledet.
 */
class ShowMoreEvents {
    constructor(eventlist) {
        this.moreButton = eventlist.querySelector('.js-show-more-events');

        if (this.moreButton) {
            this.moreButton.addEventListener('click', () => {
                const hiddenEvents = eventlist.querySelectorAll('.js-event-teaser.is-hidden');

                // Der Button nicht mehr benötigt, wenn nach diesem Klick alle Events angezeigt werden
                if (hiddenEvents.length <= 10) {
                    this.moreButton.classList.add('is-hidden');
                }

                // NodeList in Array konvertieren, damit slice funktioniert
                let eventsToShow = Array.prototype.slice.call(hiddenEvents);

                // nimm die ersten 10 Events
                eventsToShow = eventsToShow.slice(0, 10);

                eventsToShow.forEach((event) => {
                    event.classList.remove('is-hidden');
                });
            });
        }
    }
}

const showMoreEvents = {
    init() {
        const eventlist = document.querySelector('.js-eventlist');

        if (eventlist) {
            this.instance = new ShowMoreEvents(eventlist);
        }
    },
};

export default showMoreEvents;
