/**
 * Slider mit Jobangeboten, der am Fuß der meisten Seiten ausgespielt wird
 */
import Swiper from 'swiper';

const jobSlider = {
    init() {
        // eslint-disable-next-line no-new
        new Swiper('.js-ing-job-slider', {
            slidesPerView: 1,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            spaceBetween: 28,
            loop: true,
            navigation: {
                nextEl: '.js-ing-job-slider-button-next',
                prevEl: '.js-ing-job-slider-button-prev',
            },
            breakpoints: {
                992: {
                    slidesPerView: 2,
                },
            },
            autoplay: false,
        });
    },
};

export default jobSlider;
