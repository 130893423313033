export default function tableOfContent() {
    const tableOfContents = document.querySelectorAll('.js-table-of-content-link');

    Array.from(tableOfContents).forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
            event.preventDefault();
            const {target: {hash}} = event;
            const targetAnchor = document.querySelector(hash);
            if (targetAnchor) {
                window.scrollTo({
                    top: targetAnchor.offsetTop - 200,
                    behavior: 'smooth',
                });
            }
        });
    });
}
