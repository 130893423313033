const getTimeCodeFromNum = (num) => {
    let seconds = parseInt(num, 10);
    let minutes = parseInt(seconds / 60, 10);
    seconds -= minutes * 60;
    const hours = parseInt(minutes / 60, 10);
    minutes -= hours * 60;

    if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
    return `${String(hours).padStart(2, 0)}:${minutes}:${String(
        seconds % 60,
    ).padStart(2, 0)}`;
};

class Audioplayer {
    constructor(element) {
        this.element = element;
        this.timeline = this.element.querySelector('.ing-audio__timeline');
        this.volumeSlider = this.element.querySelector('.ing-audio__volume-slider');
        this.playBtn = this.element.querySelector('.ing-audio__toggle-play');
        this.items = this.element.querySelectorAll('.ing-audio__item');
        this.currentTime = 0;
        this.current = 0;
        this.itemsCount = this.items.length;
        this.audio = new Audio(
            this.items[0].dataset.src,
        );
    }

    toggleTrack() {
        this.audio.src = this.items[this.current].dataset.src;
        this.audio.currentTime = 0;
        this.audio.play();
        this.element.querySelector('.ing-audio__name').innerText = this.items[this.current].dataset.title;
        this.element.querySelector('.ing-audio__subline').innerText = this.items[this.current].dataset.artist;
        if (this.playBtn.classList.contains('play')) {
            this.togglePlayButton('pause', 'play');
        }
    }

    next() {
        if (this.current === this.itemsCount - 1) {
            this.current = 0;
            this.toggleTrack();
            return;
        }

        this.current += 1;
        this.toggleTrack();
    }

    prev() {
        if (this.current === 0) {
            this.current = this.itemsCount - 1;
            this.toggleTrack();
            return;
        }

        this.current -= 1;
        this.toggleTrack();
    }

    init() {
        this.addEventListener();
        this.setIntervals();
    }

    addEventListener() {
        this.items.forEach((item, index) => {
            item.addEventListener('click', () => {
                this.current = index;
                this.toggleTrack();
            });
        });

        this.timeline.addEventListener('click', ({offsetX}) => {
            const timelineWidth = window.getComputedStyle(this.timeline).width;
            this.audio.currentTime = (offsetX / parseFloat(timelineWidth)) * this.audio.duration;
        }, false);

        this.audio.addEventListener(
            'loadeddata',
            () => {
                this.element.querySelector('.ing-audio__length').textContent = getTimeCodeFromNum(
                    this.audio.duration,
                );
                this.audio.volume = 0.75;
            },
            false,
        );

        this.volumeSlider.addEventListener('click', (e) => {
            const sliderWidth = window.getComputedStyle(this.volumeSlider).width;
            const newVolume = e.offsetX / parseInt(sliderWidth, 10);
            this.audio.volume = newVolume;
            this.element.querySelector('.ing-audio__volume-percentage').style.width = `${newVolume * 100}%`;
        }, false);

        this.playBtn.addEventListener(
            'click',
            () => {
                if (this.audio.paused) {
                    this.togglePlayButton('pause', 'play');
                    this.audio.play();
                } else {
                    this.togglePlayButton('play', 'pause');
                    this.audio.pause();
                }
            },
            false,
        );

        this.element.querySelector('.ing-audio__volume-button').addEventListener('click', () => {
            const volumeEl = this.element.querySelector('.ing-audio__volume');
            this.audio.muted = !this.audio.muted;
            if (this.audio.muted) {
                volumeEl.classList.add('ing-audio__volume--muted');
            } else {
                volumeEl.classList.remove('ing-audio__volume--muted');
            }
        });

        const next = this.element.querySelector('.ing-audio__prev');
        if (next) {
            next.addEventListener('click', () => {
                this.next();
            });
        }

        const prev = this.element.querySelector('.ing-audio__next');
        if (prev) {
            prev.addEventListener('click', () => {
                this.prev();
            });
        }
    }

    togglePlayButton(add, remove) {
        this.playBtn.classList.remove(remove);
        this.playBtn.classList.add(add);
    }

    setIntervals() {
        setInterval(() => {
            const progressBar = this.element.querySelector('.ing-audio__progress');
            progressBar.style.width = `${(this.audio.currentTime / this.audio.duration) * 100}%`;

            this.element.querySelector('.ing-audio__current').textContent = getTimeCodeFromNum(
                this.audio.currentTime,
            );
        }, 500);
    }
}

const audioplayer = {
    init() {
        const players = document.querySelectorAll('.ing-audio');
        players.forEach((player) => {
            const instance = new Audioplayer(player);
            instance.init();
        });
    },
};

export default audioplayer;
