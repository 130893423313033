class CookieWatcher {
    constructor(key, callback, isConditional = false) {
        this.key = key;
        this.callback = callback;
        this.isConditional = isConditional;
        this.callback.bind(this);
    }

    init() {
        this.listenCookieChange();
        this.callback(this.readCookie(this.key));
    }

    readCookie() {
        const nameEQ = `${this.key}=`;
        const ca = document.cookie.split(';');
        // eslint-disable-next-line no-plusplus
        for (let i = 0, counter = ca.length; i < counter; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    checkInterval() {
        const value = this.readCookie(this.key);
        if (value) {
            this.callback(value);
            clearInterval(this.intervalId);
        }
    }

    checkConditionalInterval() {
        const value = this.readCookie(this.key);
        if (value) {
            const result = this.callback(value);
            if (result) {
                clearInterval(this.intervalId);
            }
        }
    }

    listenCookieChange() {
        if (this.isConditional) {
            this.intervalId = setInterval(this.checkConditionalInterval.bind(this), 100);
            return;
        }
        this.intervalId = setInterval(this.checkInterval.bind(this), 100);
    }
}

const cookieWatcher = {
    init() {
        this.watchCleverPush();
    },
    watchCleverPush() {
        if (document.body.classList.contains('disable-cleverpush')) {
            return;
        }

        const cleverPushCookieWatcher = new CookieWatcher('cleverpush-opt-in', (value) => {
            if (value !== null
                && value !== 'false'
                && !document.querySelector('#cleverpush-sdk')) {
                const cleverpushScript = document.createElement('script');
                cleverpushScript.setAttribute('id', 'cleverpush-sdk');
                cleverpushScript
                    .setAttribute('src', 'https://static.cleverpush.com/channel/loader/pAv3LZRCm84QeJEfY.js');
                document.body.appendChild(cleverpushScript);
            }
        });
        cleverPushCookieWatcher.init();
    },
};

const vdiCookieWatcher = (key, callback, isConditional = false) => {
    const watcher = new CookieWatcher(key, callback, isConditional);
    watcher.init();
};

export default cookieWatcher;
export {vdiCookieWatcher};
