/**
 * Accordion-Shortcode in Artikeln
 */
function initAccordion(element) {
    const accordionChangedEvent = new CustomEvent('accordionChange');
    const button = element.querySelector('.js-accordion-headline');
    const content = element.querySelector('.js-accordion-content');

    if (!button || !content) {
        return;
    }

    button.addEventListener('click', (e) => {
        e.target.blur();

        if (element.classList.contains('active')) {
            element.classList.remove('active');
            content.style.height = '0px';
            return;
        }

        content.style.height = `${content.scrollHeight}px`;
        element.classList.add('active');

        window.dispatchEvent(accordionChangedEvent);
    });
}

export default function accordion() {
    const accordions = document.querySelectorAll('.js-accordion');

    accordions.forEach((element) => {
        initAccordion(element);
    });
}
