class RecruitingRegistration {
    constructor(triggers) {
        this.triggers = triggers;
    }

    init() {
        this.addEventListener();
    }

    addEventListener() {
        this.triggers.forEach((trigger) => {
            trigger.addEventListener('change', (event) => {
                RecruitingRegistration.showCompanyFieldS(event);
            });
        });
    }

    static showCompanyFieldS(event) {
        const {target} = event;
        const companyFields = document.querySelector('.js-company-fields');

        if (target.value === '2' && target.checked) {
            if (companyFields) {
                companyFields.classList.add('show');
            }
        }

        if (target.value === '1' && target.checked) {
            if (companyFields) {
                companyFields.classList.remove('show');
            }
        }
    }
}

const recruitingRegistration = {
    init() {
        const triggers = document.querySelectorAll('.js-private-or-company-trigger');
        const instance = new RecruitingRegistration(triggers);
        instance.init();
    },
};

export default recruitingRegistration;
