/**
 * Selects auf der Event-Übersicht-Seite schicken das Formular ab
 */
const eventFormSubmit = {
    init() {
        const eventSelects = document.querySelectorAll('.js-event-select');

        eventSelects.forEach((thisSelect) => {
            thisSelect.addEventListener('change', (event) => {
                const form = event.target.closest('form');
                form.submit();
            });
        });
    },
};

export default eventFormSubmit;
