/**
 * Funktionen zum durchlaufen des DOM.
 */

/**
 * Sucht nach einem Sibling von "element"
 * @param element {Node|Element}
 * @param query {String} Query-Parameter
 * @returns {Node|null}
 */
export function getSibling(element, query) {
    const parent = element.parentNode;
    return parent.querySelector(query);
}

/**
 * Durchsucht das DOM aufwärts ab "baseElement nach einem Elternelement mit "className".
 * @param baseElement {Node|Element} Das Element von dem aus gesucht werden soll.
 * @param className {string} Klassenname des Elternelement
 * @returns {Node|null} Gibt entweder das gesuchte Elternelement oder null zurück.
 */
export function getParentByClassName(baseElement, className) {
    let element = baseElement;

    for (; element && element !== document; element = element.parentNode) {
        const isParentListItem = [].includes.call(element.classList, className);
        if (isParentListItem) {
            return element;
        }
    }

    return null;
}
