import Swiper from 'swiper';

/**
 * "Teilnehmerstimmen" auf den Recruiting-Tag-Seiten
 */
const recruitingSlider = {
    init() {
        // eslint-disable-next-line no-new
        new Swiper('.js-recruiting-slider', {
            slidesPerView: 1,
            navigation: {
                nextEl: '.js-button-next',
                prevEl: '.js-button-prev',
            },
        });
    },
};

export default recruitingSlider;
