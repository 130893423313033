export default function loadScriptService(src, callback) {
    let loaded = false;
    const tag = document.createElement('script');
    const firstScriptTag = document.getElementsByTagName('script')[0];

    if (!firstScriptTag) {
        return;
    }

    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    tag.onload = () => {
        if (!loaded) {
            loaded = true;
            callback();
        }
    };

    tag.onreadystatechange = () => {
        if (!loaded && (this.readyState === 'complete' || this.readyState === 'loaded')) {
            loaded = true;
            callback();
        }
    };

    tag.src = src;
}
